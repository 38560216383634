import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";


const AboutUs = ({t}) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid page">
      <div className="mt-5 pt-5">
        <div className="title">
          <h3>{t('about-us')}</h3>
        </div>
        <div className="content">
          <p>{t('about-us-msg.para1')}</p>
          <p>{t('about-us-msg.para2')}</p>
          <p>{t('about-us-msg.para3')}</p>
          <p>{t('about-us-msg.para4')}</p>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(AboutUs);
