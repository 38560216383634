import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";


const PrivacyPolicy = ({t}) => {

  useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
  
 return (
  <div className="container-fluid page">
   <div className="mt-5 pt-5">
   <div className="title">
     <h3>{t('privacy-policy')}</h3>
    </div>
    <div className="content">
      <p>{t('privacy-msg.para1')}</p>
      <p>{t('privacy-msg.para2')}</p>

   </div>
   </div>
  </div>

 );
};
export default withNamespaces()(PrivacyPolicy);
