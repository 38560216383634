import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";


const Cookies = ({t}) => {

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <div className="container-fluid page">
         <div className="mt-5 pt-5">
            <div className="title">
               <h3>{t('cookies')}</h3>
            </div>
            <div className="content">
               <p>{t('cookies-msg.para1')}</p>
               <p>{t('cookies-msg.para2')}</p>
               <p>{t('cookies-msg.para3')}</p>

            </div>
         </div>
      </div>
   );
};
export default withNamespaces()(Cookies);
