import { combineReducers } from "redux";
//imports multiple reducer here


const rootReducer = combineReducers({
  //and use it here
 
});

export default rootReducer;
