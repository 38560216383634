import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";


const FAQ = () => {

  useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

 return (
  <div className="container-fluid page">
   <div className="mt-5 pt-5">
   <div className="title border mt-5">
     <h1>COMING SOON</h1>
    </div>
   </div>
  </div>
 );
};
export default withNamespaces()(FAQ);
