import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationEN from '../helper/localizations/en.json';
import translationGR from '../helper/localizations/gr.json';
import translationFR from '../helper/localizations/fr.json';
import translationIT from '../helper/localizations/it.json';


// the translations
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    it: {
        translation: translationIT
    },
    gr: {
        translation: translationGR
    },
};

i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "gr",

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;