/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import debounce from "lodash/debounce";
import { globalConfigContext } from "@context/GlobalConfigContext";
import * as yup from "yup";
// import { string, object } from 'yup'; // can be used for optimization
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import poster from "@assets/pictures/poster.png";
import gogoLogo from "@assets/pictures/mycinema-logo.png";


import { motion } from "framer-motion";


const Header = ({ t, location }) => {
  

  // React Bootstrap Modal
  
  return (
    <header>
      
      {/* Start Desktop Header */}
      <motion.div
        className="main-menu"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="container-fluid container-xl">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-5 col-md-4 px-0">
              <div className="row align-items-center">
                <div className="col-4 col-md-6 col-xl-5">
                  <Link to="/">
                    <img
                      src={gogoLogo}
                      alt="Logo"
                      className="brand-logo"
                      width="300px"
                    />
                  </Link>
                </div>
                </div>
              </div>
            </div>
           
        </div>
      </motion.div>
      {/* End Desktop Header */}

      
    </header>
  );
};

export default withNamespaces()(Header);
