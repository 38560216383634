import React, { useEffect, useState, useRef, useContext } from "react";
import { withNamespaces } from "react-i18next";

import { useHistory } from "react-router-dom";
import cinemaBg from "@assets/pictures/cinema-banner.png";
import sampleBg from "@assets/pictures/sample-bg.png";

import hiwOne from "@assets/pictures/how-3.jpg";
import hiwTwo from "@assets/pictures/how-2.jpg";
import hiwFive from "@assets/pictures/hiw-one.png";

import hiwFour from "@assets/pictures/how-1.jpg";
import whiteArrowDown from "@assets/pictures/svgs/white-arrow-down.svg";

import logo2 from "@assets/pictures/logo-2.png";
import Slider from "react-slick";


const HowItWorks = ({ t }) => {
	// const history = useHistory();

	const [modalShow, setModalShow] = useState([false, 0]);

	const heroSlider = {
		arrows: false,
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 2000,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					arrows: false,
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<div className="px-0">
				{/* Hero Section */}
				<Slider {...heroSlider} className="dots-1">
					<section className="cinema-hero-section">
						<div className="hero-img-section">
							<img src={hiwOne} alt="Movie Banner" className="hero-img-1" />
						</div>
						<div className="hero-text justify-content-center">
							<div className="container-fluid container-xl">
								<div className="row">
									<div className="col-12 col-sm-8 col-lg-6 mx-auto text-center">
										<p className="title">{t('landing-page.title1')}</p>
										<p className="msg">
										{t('landing-page.para1')}

                      </p>
										<div className="d-flex justify-content-center arrow-down">
											<a href="#content">
												<img src={whiteArrowDown} href="#content" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="cinema-hero-section">
						<div className="hero-img-section">
							<img src={hiwFour} alt="Movie Banner" className="hero-img-1" />
						</div>
						<div className="hero-text justify-content-center">
							<div className="container-fluid container-xl">
								<div className="row">
									<div className="col-12 col-sm-8 col-lg-6 mx-auto text-center">
										<p className="title">{t('landing-page.title2')}</p>
										<p className="msg">
										{t('landing-page.para2')}
                      </p>
										<div className="d-flex justify-content-center arrow-down">
											<a href="#content">
												<img src={whiteArrowDown} href="#content" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="cinema-hero-section">
						<div className="hero-img-section">
							<img src={sampleBg} alt="Movie Banner" className="hero-img-1" />
						</div>
						<div className="hero-text justify-content-center">
							<div className="container-fluid container-xl">
								<div className="row">
									<div className="col-12 col-sm-8 col-lg-6 mx-auto text-center">
										<p className="title">{t('landing-page.title3')}</p>
										<p className="msg">
										{t('landing-page.para3')}

                      </p>
										<div className="d-flex justify-content-center arrow-down">
											<a href="#content">
												<img src={whiteArrowDown} href="#content" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

				</Slider>
				{/* Hero Section Ends */}

				<section className="container-fluid px-3 px-md-3 px-lg-5 px-xl-5 mt-3" id="content">
					<div className="row home-content align-items-center justify-content-center ">
						<div
							className="col-12 col-sm-6 col-lg-5 mb-3 mb-sm-0"
						>
							<div className="d-flex flex-column h-100">
								<div>
									<p className="title">{t('section-one.title')}</p>
									<p className="msg">
									{t('section-one.para1')} 
                 <br/><br/>   {t('section-one.para2')}<br />
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-6 text-center">
							{/* <img className="img-fluid" src={hiwOne} alt="" /> */}
							<div>
								<iframe src="https://player.vimeo.com/video/517238877?title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
							</div>
						</div>
					</div>

					<div className="row home-content align-items-center justify-content-center ">

						<div className="col-12 col-sm-6 col-lg-6 text-center order-1">
							<img className="img-fluid" src={hiwFive} alt="" />

						</div>
						<div
							className="col-12 col-sm-6 col-lg-5 mb-3 mb-sm-0 order-md-2 order-lg-2 order-xl-2"
						>
							<div className="d-flex flex-column h-100">
								<div>
									<p className="title">{t('section-two.title')}</p>
									<p className="msg"> {t('section-two.para1')}</p>
								</div>
							</div>
						</div>

					</div>

					<div className="row home-content align-items-center justify-content-center ">
						<div
							className="col-12 col-sm-6 col-lg-5 mb-3 mb-sm-0"
						>
							<div className="d-flex flex-column h-100">
								<div>
									<p className="title">{t('section-three.title')}</p>
									<p className="msg">
									{t('section-three.para1')}
<br />

									</p>
								</div>
							</div>
						</div>

						<div className="col-12 col-sm-6 col-lg-6 text-center">
							<iframe src="https://player.vimeo.com/video/517241357?title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

						</div>

					</div>

					<div className="row home-content align-items-center justify-content-center ">

						<div className="col-12 col-sm-6 col-lg-6 text-center order-1">
							<img className="img-fluid" src={hiwTwo} alt="" />

						</div>
						<div
							className="col-12 col-sm-6 col-lg-5 mb-3 mb-sm-0 order-md-2 order-lg-2 order-xl-2"
						>
							<div className="d-flex flex-column h-100">
								<div>
									<p className="title">{t('section-four.title')}</p>
									<p className="msg">
									{t('section-four.para1')}
                  <br />
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="row home-content align-items-center justify-content-center ">

						<div
							className="col-12 col-sm-6 col-lg-5 mb-3 mb-sm-0"
						>
							<div className="d-flex flex-column h-100">
								<div>
									<p className="title">{t('section-five.title')}</p>
									<p className="msg">
									{t('section-five.para1')}
        </p>
								</div>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-lg-6 text-center">
							<iframe src="https://player.vimeo.com/video/517238520?title=0&byline=0&portrait=0"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
						</div>

					</div>

				</section>
			</div>
		</div>
	);
};
export default withNamespaces()(HowItWorks);
