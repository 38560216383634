import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { globalConfigContext } from "@context/GlobalConfigContext";
import i18n from "@plugins/i18n";
import logo2 from "@assets/pictures/logo-2.png";
import { LanguageService } from '@apiService/tokenService';

import gogoLogo from "@assets/pictures/mycinema-logo.png";
import {
  Dropdown
} from "react-bootstrap";


import { motion } from "framer-motion";

function Footer({ t }) {

  const [selectedLang, setSelectedLang] = useState(null);
  const onClickLang = (index) => {
    setSelectedLang(langsList[index]);
    i18n.changeLanguage(langNotationList[index]);

  }

  const langsList = [ "English", "German", "French", "Italian"];
  const langNotationList = ["en", "gr", "fr", "it"];

  return (
    <footer>
      <motion.div
        className="container-fluid container-xl bottom-menu-space"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="row">
          <div className="col">
            <div className="actual-footer">

              
              <div className="row justify-content-center">
                <div className="col-md-10 col-lg-9">
                  <div
                    className="d-flex justify-content-between flex-wrap"
                    id="first-row"
                  >
                    {/* <div className="col-sm-4"> */}
                    <div className="footer-links">
                      <p>{t('for-cinemas')}</p>
                      <Link to="/how-it-works">{t('hiw')}</Link>
                    </div>
                    {/* </div> */}
                    {/* <div className="col-sm-4"> */}
                    <div className="footer-links">
                      <p>{t('company')}</p>
                      <Link to="/about-us">{t('about-us')}</Link>
                      <a href="mailto:contact@imaculix.ch">{t('contact')}</a>
                      <Link to="/frequently-asked-questions">{t('faq')}</Link>
                    </div>
                    {/* </div> */}
                    {/* <div className="col-sm-4"> */}
                    <div className="footer-links">
                      <Link to="/documentation">{t('imprint')}</Link>
                      <Link to="/terms-and-conditions">{t('disclaimer')}</Link>
                      <Link to="/cookies">{t('cookies')}</Link>
                      <Link to="/privacy-policy">{t('privacy-policy')}</Link>
                    </div>
                  </div>
                  <div className="row justify-content-center d-flex">
                    <div className="col-sm-10 col-md-8 col-lg-5">
                      <div className="footer-sm-links">
                        <Link to="#">FACEBOOK</Link>
                        <Link to="#">INSTAGRAM</Link>
                      </div>
                    </div>
                  </div>
                  <div className="row" id="last-row">
                    <div className="col-12 text-center">
                      <div className="copyright-text d-flex align-items-center justify-content-center">
                        <img src={gogoLogo} className="img-one" />
                        <p className="ml-n2">a Brand of </p>
                        <img src={logo2} className="pl-2 img-two" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>

      </motion.div>

      <div className="lang-dropdown">
                
                <Dropdown className="">
                  <Dropdown.Toggle
                    className=""
                    id="dropdown-basic"
                  >
                    <span className="title"> {selectedLang
                      ? selectedLang
                      : "Select Language"} </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="right" className="dropdownItems">
                    {langsList?.map((lang, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => onClickLang(index)}
                      >
                        {lang}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
    </footer>
  );
}
export default withNamespaces()(Footer);
