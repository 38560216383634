import React, { useState, useEffect, useContext,   useLayoutEffect } from "react";
import { Provider } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import "moment/locale/ar";
//import store
import store from "./store/index";
// import router
import Router from "./Router";
//import i18n
import "./plugins/i18n";
//import compoents
import Header from "@components/partials/Header.jsx";
import Footer from "@components/partials/Footer";
//CSS Imports
// import '@assets/css/App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@assets/css/slick.css";

import "@assets/css/style.css";

import "react-modal-video/css/modal-video.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const App = ({  }) => {
 

  useEffect(() => {
    // let mainSpinner = document.getElementById('main-spinner');
    // if(mainSpinner) {
    // 	mainSpinner.style.display = "none";
    // }
  }, []);



  return  (
    <Provider store={store}>
      <Header />
      <main className="App">
        <Router/>
      </main>
      <Footer />
    
      
    </Provider>
  ) 
};

export default withNamespaces()(App);
