import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { AnimatedSwitch, spring } from "react-router-transition";
// //import components

import HowItWorks from "@components/HowItWorks.jsx";
import Home from "@components/Home.jsx";
import FAQ from "@components/FAQ.jsx";
import TnC from "@components/TnC.jsx";
import PrivacyPolicy from "@components/PrivacyPolicy.jsx";
import Cookies from "@components/Cookies.jsx";
import AboutUs from "@components/AboutUs.jsx";
import Documentation from "@components/Documentation.jsx";

const Router = () => {
 
 
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/terms-and-conditions" component={TnC} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/cookies" component={Cookies} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/how-it-works" component={HowItWorks} />
      <Route exact path="/frequently-asked-questions" component={FAQ} />
      <Route exact path="/documentation" component={Documentation} />
    </Switch>
  );
};
export default Router;
